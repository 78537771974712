<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        <b>
          {{
            inputs.language === 'en'
              ? 'Select all that apply.'
              : 'Sélectionnez toutes les bonnes réponses possibles.'
          }}
        </b>
        {{
          inputs.language === 'en'
            ? 'Why is water used for liquid-liquid extractions?'
            : "Pourquoi l'eau est utilisée pour les extractions liquide-liquide?"
        }}
      </p>

      <p
        v-for="option in inputs.language === 'en' ? optionsShuffled : optionsShuffledFr"
        :key="'pt-1-' + option.value"
        class="mb-0 mt-0"
      >
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mt-5 mb-0">
        <v-btn-toggle
          v-model="inputs.language"
          class="ml-2"
          size="small"
          dense="dense"
          rounded="rounded"
          mandatory
        >
          <v-btn :value="'en'">English</v-btn>
          <v-btn :value="'fr'">Français</v-btn>
        </v-btn-toggle>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'uOttawa1321Prelab3Q8',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswers: [],
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'To remove salts', value: 'removeSalts'},
        {text: 'Because it is inexpensive', value: 'inexpensive'},
        {text: 'To remove inorganic impurities', value: 'removeImpurities'},
        {
          text: 'Because it is good to separate neutral organic components',
          value: 'separateComponents',
        },
        {text: 'Because it is more environmentally friendly', value: 'environment'},
      ],
      optionsFr: [
        {text: "Parce que c'est peu dispendieux", value: 'inexpensive'},
        {
          text: "Parce que c'est efficace pour séparer les composés organiques neutres",
          value: 'separateComponents',
        },
        {text: "Parce que c'est meilleur pour l'environnement", value: 'environment'},
        {text: 'Pour retirer les sels', value: 'removeSalts'},
        {text: 'Pour retirer les impuretés inorganiques', value: 'removeImpurities'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    optionsShuffledFr() {
      return seededShuffle(this.optionsFr, this.seed);
    },
  },
};
</script>
